import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";
import React, { useState } from 'react';
import FsLightbox from 'fslightbox-react'; 
import Lightbox from 'react-image-lightbox-custom-content-aut';
import 'react-image-lightbox-custom-content-aut/style.css'; // This only needs to be imported once in your app
import ReactPlayer from 'react-player'
import "./Projects.css" 

const container = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const projectVariant = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};




const Project = ({ id, title, info }) => {
  const overlayStyles = `absolute h-full w-full opacity-0 hover:opacity-90 transition duration-500
    bg-grey z-30 flex flex-col justify-center items-center text-center p-16 text-deep-blue`;
  const projectID = id;
  const projectTitle = title.split(" ").join("-").toLowerCase();
  const projectInfo = info;

  const [intIsLightboxOpen, setLightboxOpen] = useState(0);
  const [intPhotoIndex, setPhotoIndex] = useState(0)
 
  const proj1images = [
    '../assets/coldash/coldash.png',
    '../assets/coldash/coldash2.png',
 ];
  const proj4images = [
    '../assets/topnosh/1.png',
    '../assets/topnosh/2.png',
    '../assets/topnosh/3.png',
  ];
  const proj5images = [
    '../assets/jwadv/1.png',
    '../assets/jwadv/2.png',
    '../assets/jwadv/3.png',
  ];




  return (

    <motion.div variants={projectVariant} className="relative">
      {/*{console.log("projectID: "+ projectID)} */}
      <div className={overlayStyles} id="cursorHover" onClick={() => setLightboxOpen(Number(projectID))} >
        <p className="text-2xl font-playfair">{title}</p>
        <p className="mt-7" >
          {projectInfo}
        </p>
      </div>
      <img src={`../assets/${projectTitle}.png`} alt={projectTitle} />
      
    {(intIsLightboxOpen===1) && (    
      <Lightbox
        imageTitle={"Cold Ash - Landing Page"}
        imageCaption={"A new landing page was requested for an online clothing marketplace. The site uses pure HTML and CSS, with responsive elements to cater for all browser types and screen sizes, including large desktop displays, phones and tablets. Photoshop was used to create new image elements."}
        imagePadding={80}
        enableZoom={false}
        mainSrc={proj1images[intPhotoIndex]}
        nextSrc={proj1images[(intPhotoIndex + 1) % proj1images.length]}
        prevSrc={proj1images[(intPhotoIndex + proj1images.length - 1) % proj1images.length]}
        onMovePrevRequest={() => setPhotoIndex ((intPhotoIndex + proj1images.length - 1) % proj1images.length)}
        onMoveNextRequest={() => setPhotoIndex ((intPhotoIndex + 1) % proj1images.length)}
        clickOutsideToClose={true}
        onImageLoad={() => {
          window.dispatchEvent(new Event('resize'));
        }}
          onCloseRequest={map => {setLightboxOpen(0); setPhotoIndex(0);}}
        />  
      )
    }
    {(intIsLightboxOpen===2) && (    
      <Lightbox
        //mainSrc={"https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Cat03.jpg/1200px-Cat03.jpg"}
        imageTitle={"Helga Arvesten - THE EDGE (music video)"}
        imageCaption={"Helga Arvesten, an independent musician from Sweden, requested a music video be edited. I provided general timeline editing, shot matching, color grading and VFX techniques using Adobe Premiere to deliver a professionally edited music video for my client."}
        mainCustomContent={<div className="player-wrapper" onClick={() => setLightboxOpen(Number(0))}>  
          <ReactPlayer
          url='https://www.youtube.com/watch?v=pmBSlpb7MXA'
          className='react-player'
          playing
          width='60%'
          height='60%'
          controls='true'
          volume='0.5'
          /> 
          </div> 
        }
        enableZoom={false}
        onImageLoad={() => {
          window.dispatchEvent(new Event('resize'));
        }}
          onCloseRequest={() => setLightboxOpen(0)}
        />  
      )
    }
    {(intIsLightboxOpen===3) && (    
      <Lightbox
        imageTitle={"Exergame Prototype - Final Year Project"}
        imageCaption={"I developed a Unreal Engine 4 VR game where players can pedal a real bike in order to traverse in a virtual bike race. We investigated the gamification of HIIT (high intensity interval training) to assess whether gaming can distract users from exercise. A user experiment that analysed heart-rate data, distanced travelled and rated perceived exertion (RPE) suggested this was the case."}
        mainCustomContent={<div className="player-wrapper" onClick={() => setLightboxOpen(Number(0))}>  
          <ReactPlayer
          url='https://www.youtube.com/watch?v=Yd1iqdqZG8g'
          className='react-player'
          playing
          width='60%'
          height='60%'
          controls='true'
          volume='0.5'
          /> 
          </div> 
        }
        enableZoom={false}
        onImageLoad={() => {
          window.dispatchEvent(new Event('resize'));
        }}
         onCloseRequest={() => setLightboxOpen(0)}
        />  
      )
    }
    {(intIsLightboxOpen===4) && (    
      <Lightbox
        imageTitle={"Top Nosh - University Project"}
        imageCaption={"I worked in a team developing a semester-long project, a cooking app developed using Java. I created the app concept, front-end wireframe designs, data dictionaries, as well as handling the database design, implementation, and data serialisation/deserialisation."}
        imagePadding={80}
        enableZoom={false}
        mainSrc={proj4images[intPhotoIndex]}
        nextSrc={proj4images[(intPhotoIndex + 1) % proj4images.length]}
        prevSrc={proj4images[(intPhotoIndex + proj4images.length - 1) % proj4images.length]}
        onMovePrevRequest={() => setPhotoIndex ((intPhotoIndex + proj4images.length - 1) % proj4images.length)}
        onMoveNextRequest={() => setPhotoIndex ((intPhotoIndex + 1) % proj4images.length)}
        clickOutsideToClose={true}
        onImageLoad={() => {
          window.dispatchEvent(new Event('resize'));
        }}
          onCloseRequest={map => {setLightboxOpen(0); setPhotoIndex(0);}}
        />    
      )
    }
    {(intIsLightboxOpen===5) && (    
      <Lightbox
      imageTitle={"JW Advisory and Design - College Project"}
      imageCaption={"I created a conceptual website for a cloud services advisory and design business using HTML, CSS, JS, MySQL and PHP. It includes functional posting, product basket and user login systems. Layout was handled by CSS and image elements created using Adobe Photoshop."}
      imagePadding={80}
      enableZoom={false}
      mainSrc={proj5images[intPhotoIndex]}
      nextSrc={proj5images[(intPhotoIndex + 1) % proj5images.length]}
      prevSrc={proj5images[(intPhotoIndex + proj5images.length - 1) % proj5images.length]}
      onMovePrevRequest={() => setPhotoIndex ((intPhotoIndex + proj5images.length - 1) % proj5images.length)}
      onMoveNextRequest={() => setPhotoIndex ((intPhotoIndex + 1) % proj5images.length)}
      clickOutsideToClose={true}
      onImageLoad={() => {
        window.dispatchEvent(new Event('resize'));
      }}
        onCloseRequest={map => {setLightboxOpen(0); setPhotoIndex(0);}}
      />      
      )
    }    
    </motion.div>
  );
};

const Projects = () => {
  return (
    <section id="projects" className="pt-48 pb-48">
      {/* HEADINGS */}
      <motion.div
        className="md:w-2/5 mx-auto text-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div>
          <p className="font-playfair font-semibold text-4xl">
            <span className="text-red">PRO</span>JECTS
          </p>
          <div className="flex justify-center mt-5">
            <LineGradient width="w-2/3" />
          </div>
        </div>

      
        <p className="mt-10 mb-10">
          Below are some projects I have worked on in the past, including web development, game development, multimedia projects and more.
        </p>
      </motion.div>

      {/* PROJECTS */}
      <div className="flex justify-center">
        <motion.div
          className="sm:grid sm:grid-cols-3"
          variants={container}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
        >
          {/* ROW 1 */}
          <div
            className="flex justify-center text-center items-center p-10 bg-red
              max-w-[400px] max-h-[400px] text-2xl font-playfair font-semibold"
          >
            RESPONSIVE <br /> DETAIL-ORIENTED <br />DESIGN
          </div>
          <Project id="1" title="Cold Ash - Landing Page" /*info="A new landing page was requested for an online clothing marketplace. The site uses pure HTML and CSS, with responsive elements to cater for all browser types and screen sizes, including large desktop displays, phones and tablets. Photoshop was used to create new image elements."*/  />
          <Project id="2" title="Helga Arvesten - THE EDGE (music video)" /*info="Helga Arvesten, an independent musician from Sweden, requested a music video be edited. I provided general timeline editing, shot matching, color grading and VFX techniques using Adobe Premiere to deliver a professionally edited music video for my client."*/ />

          {/* ROW 2 */}
          <Project id="3" title="Exergame Prototype - Final Year Project" /*info="I developed a Unreal Engine 4 VR game where players can pedal a real bike in order to traverse in a virtual bike race. We investigated the gamification of HIIT (high intensity interval training) to assess whether gaming can distract users from exercise. A user experiment that analysed heart-rate data, distanced travelled and rated perceived exertion (RPE) suggested this was the case."*/ />
          <Project id="4" title="Top Nosh - University Project" /*info="I worked in a team developing a semester-long project, a cooking app developed using Java. I created the app concept, front-end wireframe designs, data dictionaries, as well as handling the database design, implementation, and data serialisation/deserialisation."*/ />
          <Project id="5" title="JW Advisory and Design - College Project" /*info="I created a conceptual website for a cloud services advisory and design business using HTML, CSS, JS and PHP. It includes functional posting, product basket and user login systems using SQL, JS and PHP. I created all visual elements myself using Adobe Photoshop."*/ />

          {/* ROW 3 */}
          {/*<Project title="Project 6" />
          <Project title="Project 7" />
          <div
            className="flex justify-center text-center items-center p-10 bg-blue
              max-w-[400px] max-h-[400px] text-2xl font-playfair font-semibold"
          >
            SMOOTH USER EXPERIENCE
          </div>
         */}
        </motion.div>
      </div>
    </section>
  );
};

export default Projects;
